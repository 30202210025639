<template>
  <div class="container">
    <div class="header" ref="header">
      <div class="left">
        <div class="menuList-item" v-for="item in subRouterPath" :key="item.path" @click="pageToogle(item)">
          <i :class="item.icon" ref="texts0" :style="item.name == clickName ? 'color:#ffd04b' : ''"></i>
          <div ref="texts1" :style="item.name == clickName ? 'color:#ffd04b' : ''">{{ item.title }}</div>
        </div>
      </div>
      <div class="title" ref="texts2">
        <!-- 秦港六公司能源管理信息系统 -->
        {{ titleText }}
      </div>
      <div class="time" ref="texts3">
        {{ time }}
        <i class="iconfont icon-iotechfanhui" style="margin:0 30px;font-size: 24px;" @click="$router.push('/select')"></i>
      </div>
    </div>
    <div class="content">
      <div class="menuList-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import ShowHeader from '@/components/deviceClass/ShowHeader'

import AccountManage from '@/api/manageApi/AccountManage'
import user from "@/api/manageApi/User";

export default {
  components: {
    ShowHeader
  },
  data() {
    return {
      time: '',
      subRouterPath: [],
      clickName: '',
      users: '',
      titleText: '',
    }
  },
  created() {
    // 标题
    this.getAccount()

    for (let i = 0; i < this.$router.options.routes.length; i++) {
      if (this.$router.options.routes[i].name === 'MapEquipmentDisplay') {
        this.subRouterPath = this.$router.options.routes[i].children
      }
    }
    this.time = this.$moment().format("YYYY-MM-DD HH:mm:ss")
    this.timer = setInterval(() => {
      this.time = this.$moment().format("YYYY-MM-DD HH:mm:ss")
    }, 1000);
  },
  mounted() {
    if (this.$route.path == '/WaterReadingCard') {
      this.$refs.texts0[0].style = 'color:#ffd04b'
      this.$refs.texts1[0].style = 'color:#ffd04b'
    }
  },
  methods: {

    pageToogle(value) {
      this.clickName = value.name
      this.$router.push(value.path)
      if (value.path === '/DeviceMap') {
        for (const i in this.$refs.texts0) {
          this.$refs.texts0[i].style = 'color:#7af0ff'
          this.$refs.texts1[i].style = 'color:#7af0ff'
        }
        this.$refs.texts2.style = 'color:#7af0ff'
        this.$refs.texts3.style = 'color:#7af0ff'
        this.$refs.header.style = 'background-color:rgba(22, 42, 54,0.2)'

      } else {
        for (const i in this.$refs.texts0) {
          this.$refs.texts0[i].style = 'color:aqua'
          this.$refs.texts1[i].style = 'color:aqua'
        }
        this.$refs.texts2.style = 'color:aqua'
        this.$refs.texts3.style = 'color:aqua'
        this.$refs.header.style = 'background-color:none'
      }
    },

    // 查询当前账号用户
    async getAccount() {
      const res = await user.loginUserInfo()
      this.users = res.data.data.userName;
      if (this.users == "九公司水电展示" || this.users == "九公司") {
        this.titleText = "秦港九公司用水管理系统"
      } else if (this.users == "水电展示" || this.users == "一公司") {
        this.titleText = "一公司能源计量采集分析系统"
      } else {
        this.titleText = "水电管理系统"
      }
      // this.getTitleText()
    },
    // 查询标题信息
    getTitleText() {
      AccountManage.getShowsByUserName(this.users).then(res => {
        this.platformInfo = res.data.data.data
        if (this.platformInfo.platformName == undefined) {
          this.platformInfo.platformName = '消防物联网监控平台';
        }
        console.log(this.platformInfo);
      })
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
<style lang="scss" scoped>
@import url('../../assets/css/uiCss.scss');

@font-face {
  font-family: "led regular";
  src: url("../../assets/font/ds_digital/DS-DIGI.TTF");
}

.container {
  position: relative;
  height: 100%;

  .header {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    background-color: #555555;
    background: url("../../assets/images/header.png") no-repeat;
    background-size: 98% 160%;
    display: flex;

    .left {
      padding-top: 10px;
      width: 33.33%;
      display: flex;

      .menuList-item {
        color: aqua;
        width: 80px;
        text-align: center;
        font-size: 16px;
        padding: 10px;
        cursor: pointer;

        div {
          margin: 10px 0 0 0;
        }
      }
    }

    .title {
      width: 33.33%;
      font-size: 34px;
      text-align: center;
      line-height: 80px;
      font-weight: 600;
      color: aqua;
      letter-spacing: 2px;
      font-family: '宋体';
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
    }

    .time {
      width: 33.33%;
      color: aqua;
      font-size: 32px;
      text-align: right;
      letter-spacing: 2px;
      line-height: 80px;
      padding: 0 10px;
      font-family: 'led regular';
    }
  }

  .content {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
    background: url("../../assets/images/dptBG.jpg") no-repeat;

    .menuList-content {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
}
</style>